/**
 * include
 */
function include(file) {
	var contents = eval('include_' + file);
	if (contents) {
		document.write(contents);
	}
}
// サイト内検索
function initSearchTool() {
	var da = document.createElement('script');
	da.type = 'text/javascript';
	da.async = true;
	if ('https:' === document.location.protocol) {
		da.src = 'https://rsv.dga.jp/s/nanaco/search_tool_n3.js';
	} else {
		da.src = 'http://cache.dga.jp/s/nanaco/search_tool_n3.js';
	}
	var sc = document.getElementsByTagName('script')[0];
	sc.parentNode.insertBefore(da, sc);
}
initSearchTool();

var loadJsFilesList = [
	'/app/material/js/inc/inc_app_download.js',
	'/app/material/js/inc/inc_app_header.js',
	'/app/material/js/inc/inc_app_footer.js',
	'/app/material/js/inc/inc_app_guide.js',
	'/app/material/js/inc/inc_app_campaign.js',
	'/app/material/js/inc/inc_app_customer.js',
	'/app/material/js/inc/inc_app_evaluation.js',
	'/app/material/js/inc/inc_app_otoku.js',
	'/app/material/js/inc/inc_app_benri.js',
	'/app/material/js/inc/inc_app_footer_dl_btn.js',
	'/app/material/js/lib/slick/slick.min.js',
];

if (loadJsFilesList.length > 0) {
	for (var i in loadJsFilesList) {
		document.write('<script src="' + loadJsFilesList[i] + '"></script>');
	}
}
